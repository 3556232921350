<template>
  <div
    v-if="ast || computedParseMdcError"
    class="page-mdc-content"
    data-testid="page-mdc-content"
  >
    <NuxtLayout
      :fallback="(`${DEFAULT_PORTAL_LAYOUT}-default` as LayoutKey)"
      :frontmatter="ast?.data"
      :name="(`${customization?.layout || DEFAULT_PORTAL_LAYOUT}-default` as LayoutKey)"
    >
      <template #page-header>
        <LayoutMobilePageHeader
          :left-button="{ visible: !!sidebarLeftSnippetName, text: t('navigation.docs') }"
          :right-button="{ visible: !!sidebarRightSnippetName, text: t('navigation.on_this_page') }"
        />
      </template>

      <template #sidebar-left>
        <LayoutSidebar
          v-if="sidebarLeftSnippetName"
          :key="sidebarLeftSnippetName"
          align="left"
          class="page-mdc-sidebar-left"
          :class="{ 'open': mobileSidebarLeftIsVisible === true }"
          data-testid="page-mdc-sidebar-left"
        >
          <template #header>
            <SidebarHeader
              class="sidebar-left-header"
              data-testid="sidebar-left-header"
              @close="mobileSidebarLeftIsVisible = false"
            />
          </template>
          <PageSnippet
            inherit-data
            :name="sidebarLeftSnippetName"
          />
        </LayoutSidebar>
      </template>

      <template #default>
        <PageErrorCard
          v-if="computedParseMdcError"
          data-testid="page-mdc-parse-error"
          text-align="left"
          :title="t('errors.mdc.rendering.title', { type: t('labels.page') })"
        >
          <div
            class="page-mdc-parse-error-message"
            data-testid="page-mdc-parse-error-message"
          >
            <p>{{ t('errors.mdc.rendering.text') }}</p>
            <MDC :value="computedParseMdcError" />
          </div>
        </PageErrorCard>
        <MDCRenderer
          v-else-if="ast?.body"
          :key="parseMarkdownStatus"
          :body="ast.body"
          :data="ast.data"
        />
      </template>

      <template #sidebar-right>
        <LayoutSidebar
          v-if="sidebarRightSnippetName"
          :key="sidebarRightSnippetName"
          align="right"
          class="page-mdc-sidebar-right"
          :class="{ 'open': mobileSidebarRightIsVisible === true }"
          data-testid="page-mdc-sidebar-right"
        >
          <template #header>
            <SidebarHeader
              class="sidebar-right-header"
              data-testid="sidebar-right-header"
              @close="mobileSidebarRightIsVisible = false"
            />
          </template>
          <PageSnippet
            inherit-data
            :name="sidebarRightSnippetName"
          />
        </LayoutSidebar>
      </template>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
/**
 * This PageMdcContent component is responsible for rendering the Markdown content of a page.
 * It should be generic enough to be utilized both for the [...page_slug].vue dynamic pages, as well as
 * for the "required" portal-defined pages such as `/apis`, so that the route is enforced, but the
 * user is free to customize the page content via markdown configuration.
 */
import { parseMarkdown } from '@nuxtjs/mdc/runtime'
import type { LayoutKey } from '#build/types/layouts'

const { context, customization } = storeToRefs(usePortalStore())
const sessionStore = useSessionStore()
const { session } = storeToRefs(sessionStore)
const { mobileSidebarLeftIsVisible, mobileSidebarRightIsVisible } = storeToRefs(useLayoutStore())

const { useFetchCacheTtlSeconds } = useRuntimeConfig().public
const route = useRoute('page_slug')
const { t } = useI18n()

const { enabled: previewModeEnabled, state: previewModeState } = usePortalPreviewMode()
// Determine if preview mode is enabled, and if the page exists in the preview data
const previewModePageData = computed((): string | undefined => {
  if (!previewModeEnabled.value) {
    return
  }

  return previewModeState.value.data.pages?.get(route.path)?.content
})

const fetchKey = computed((): string => `portal-page${previewModeEnabled.value ? `-preview-${previewModeState.value.preview_id}` : ''}${route.path === '/' ? '-home' : route.path.replace(/\//g, '-')}`)
const { transform, getCachedData } = serveCachedData()

// Flag to determine if the preview mode error should be shown. Default to `false`.
const shouldShowPreviewModeError = useState<boolean>(`show-preview-mode-error-${fetchKey.value}`, () => false)

const { data: pageData, error: pageError } = await usePortalApi('/api/v3/pages/{pagePath}', {
  headers: {
    accept: 'application/json',
  },
  path: {
    pagePath: route.path, // query the page by path
  },
  // Reuse the same key to avoid re-fetching the document, e.g. `portal-page-about`
  key: fetchKey.value,
  pick: ['title', 'content', 'description', 'created_at', 'updated_at', 'visibility'], // You **must** include `content`; also referenced in `layers/core/middleware/documents.ts`
  transform,
  getCachedData,
  onRequest: () => {
    // Always reset the preview mode error state when fetching new page data. No need to check if preview mode is enabled, as this will be handled in the onMounted hook.
    shouldShowPreviewModeError.value = false
  },
})

if (
  // If no preview page data
  !previewModePageData.value &&
  // Or no pageData or 404 from API, or if the user is not authenticated and the page visibility is not 'public'
  (!pageData.value || pageError.value?.statusCode === 404 || (!session.value.authenticated && pageData.value?.visibility !== 'public'))
) {
  // If the portal authentication is enabled and the user is not authenticated, and the homepage is private, and not in preview mode, redirect to /login
  if (context.value?.authentication_enabled === true && !session.value.authenticated && route.path === '/' && !previewModeEnabled.value) {
    await navigateTo({ name: 'login-login_path' })
  } else {
    // Handle page not found error scenarios

    if (previewModeEnabled.value) {
      if (!previewModePageData.value) {
        // If in preview mode, set a flag that a 404 may need to be shown.
        shouldShowPreviewModeError.value = true

        // Defer showing the error until the onMounted hook to wait to see if the preview data is received.
      }
    } else {
      // Show the Page not found error
      showError({
        statusCode: 404,
        statusMessage: t('errors.pages.not_found'),
      })
    }
  }
} else if (!pageData.value?.content && !previewModePageData.value) {
  // Page has no content
  throw createError({
    statusCode: 204,
    statusMessage: t('errors.pages.no_content'),
    fatal: true,
  })
} else if (pageError.value && !previewModePageData.value) {
  // Other page error
  throw createError({
    statusCode: pageError.value?.statusCode || 500,
    statusMessage: parseApiError(pageError.value as any) || t('errors.pages.not_found'),
    fatal: true,
  })
}

// Important: Now that errors are processed, the page was retrieved and is viewable by the user, so clear any stored login return path cookie
if (sessionStore.getLoginReturnPath() && route.path !== '/') {
  sessionStore.setLoginReturnPath(null)
}

const emptyPageContent = '<span class="empty-page" />'
const contentToParse = computed((): string => {
  let content = ''

  if (previewModePageData.value) {
    // The `previewModePageData` computed variable ensures the page exists in the preview data
    content = previewModePageData.value
  } else {
    // Otherwise, use the page content from the API or default content
    content = pageData.value?.content || emptyPageContent
  }

  // If the content is empty, inject an empty span to prevent crashing the MDCRenderer component
  return content.endsWith('---') ? content + emptyPageContent : content
})

// Parse the previewMode content, or the raw markdown content, and return the result
const { data: ast, status: parseMarkdownStatus, error: parseMarkdownError, refresh: parseMarkdownContent } = await useAsyncData(`parsed-markdown-${fetchKey.value}`, () => parseMarkdown(contentToParse.value, {
  toc: {
    depth: 5,
    searchDepth: 5,
  },
}), {
  deep: true,
  // Watch the content for automatic reparsing
  watch: [contentToParse],
})

const computedParseMdcError = computed((): string => {
  if (!parseMarkdownError.value?.message) {
    return ''
  }

  // TODO: Expose line and column number when available in relation to the entire content document
  // Sanitize the error message to remove any line that is outputting a column number for now
  const message = parseMarkdownError.value.message.replace(/^.*column\s+\d+:.*\n?/, '').trim()
  // Format the error itself into MDC
  return `\`\`\`log\n${message}\n\`\`\``
})

// postMessage the MDC parse error to the host app
watch(() => parseMarkdownError.value?.message, (mdcParseError) => {
  if (mdcParseError) {
    if (import.meta.client) {
      postPreviewModeMessage({ action: 'portal:error:mdc', error: mdcParseError })
    }
    // If there is an MDC parse error, log it to the console
    console.warn('MDC parse error:', mdcParseError)
  }
}, { immediate: true })

// The snippet name to utilize for the sidebar-left slot, if set in the page's frontmatter
const sidebarLeftSnippetName = computed(() => ast.value?.data?.['page-layout']?.['sidebar-left'])
const sidebarRightSnippetName = computed(() => ast.value?.data?.['page-layout']?.['sidebar-right'])

// Inject the page data into child snippets
provide(SnippetPageDataInjectionKey, computed(() => ast.value?.data ? { ...ast.value?.data } : { title: '', description: '' }))

// Inject the page TOC into child components
provide(PageTocInjectionKey, computed(() => ast.value?.toc))

// Since we know there is page data, clear any global error states if there is preview mode data
watch(previewModePageData, async (previewData) => {
  if (previewData) {
    await nextTick()
    await clearError()
  }
}, { immediate: true })

// If the preview page data changes, refresh the page content
watch(() => previewModeState.value.data.pages, async (previewPageData) => {
  // When the preview page data map changes, if preview mode is enabled and the current route path is in the map, clear any global error state
  if (previewModeEnabled.value && previewPageData?.has(route.path)) {
    await parseMarkdownContent()
  }
}, { deep: true, immediate: true })

onMounted(async () => {
  // Determine if the app is running inside an iframe (likely because of previewMode)
  const inIframe = isPortalInIframe()

  // If preview mode is enabled
  if (previewModeEnabled.value) {
    postPreviewModeMessage({ action: 'portal:preview:ready' })

    // Wait the duration of the delay below
    // before showing the 404 error in order to give the host app time to
    // send the preview data payload
    if (shouldShowPreviewModeError.value) {
      await new Promise(resolve => setTimeout(resolve, inIframe ? 3000 : 0))

      await nextTick()

      if (!previewModePageData.value) {
        showError({
          statusCode: 404,
          statusMessage: t('errors.pages.not_found'),
        })
      }
    }
  }
})

defineRouteRules({
  // Disable caching when preview mode is enabled
  cache: previewModeEnabled.value ? false : {
    swr: true,
    maxAge: Number(useFetchCacheTtlSeconds || 1800),
    staleMaxAge: 300,
    // !Important: `varies` is required to cache based on the host
    varies: ['host', 'x-forwarded-host', 'origin', 'cookie', 'set-cookie'],
  },
  robots: computed((): boolean => pageData.value?.visibility === 'public').value,
})

useSeoMeta({
  title: () => ast.value?.data?.title || pageData.value?.title || '',
  description: () => ast.value?.data?.description || pageData.value?.description || '',
  // Add for lastMod support
  articleModifiedTime: () => pageData.value?.updated_at,
})

useSchemaOrg([
  defineWebPage({
    datePublished: () => pageData.value?.created_at,
    dateModified: () => pageData.value?.updated_at,
  }),
])

/**
 * Check if the user customized the og:image via frontmatter, and use this image rather than the default from @nuxt/seo.
 *
 * Example:
  ---
  title: My Custom Page
  description: Check out all the cool things we offer!
  image:
    url: https://picsum.photos/id/237/600/400
    alt: Small black puppy
    width: 600
    height: 400
  ---
 */
if (ast.value?.data?.image) {
  const { src, url, alt, width, height } = ast.value.data.image

  // `url` is the correct property in the frontmatter; however, check
  // for `src` as well in case the user is using the wrong property
  const imageUrl = typeof ast.value.data.image === 'string' ? ast.value.data.image : url || src

  if (imageUrl) {
    const w = Number(String(width || '').trim().replace('px', ''))
    const h = Number(String(height || '').trim().replace('px', ''))
    const imageWidth = isNaN(w) || Number(w) <= 0 ? undefined : w
    const imageHeight = isNaN(h) || Number(h) <= 0 ? undefined : h
    // Set the image URL, width, height, and alt text for the og:image meta tag
    defineOgImage({ url: imageUrl, width: imageWidth, height: imageHeight, alt })
  }
}
</script>

<style lang="scss" scoped>
.page-mdc-content {
  word-wrap: break-word;
}

.page-mdc-parse-error-message {
  text-align: left;

  // Hide the pre code copy button
  :deep(.prose-pre-copy-button) {
    display: none;
  }
}

.page-mdc-sidebar-left,
.page-mdc-sidebar-right {
  :deep(> .sidebar-content) {
    display: flex;
    flex-direction: column;
  }

  // Ensure page snippets expand full height
  :deep(> .sidebar-content > .page-snippet) {
    flex-grow: 1;
  }

  .sidebar-left-header,
  .sidebar-right-header {
    @media (min-width: $kui-breakpoint-tablet) {
      display: none !important; // Use important to avoid in-component styles overriding
    }
  }
}

.page-mdc-sidebar-right {
  display: flex;

  @media (min-width: $kui-breakpoint-tablet) and (max-width: ($kui-breakpoint-laptop - 1px)) {
    display: none;
  }
}
</style>
