<template>
  <header
    class="sidebar-header"
    :class="[computedSize]"
  >
    <div class="sidebar-header-info">
      <div class="sidebar-header-info-content">
        <div
          v-if="title"
          class="title"
        >
          {{ title }}
        </div>
        <PortalKBadge
          v-if="badgeText"
          :appearance="badgeAppearance"
        >
          {{ badgeText }}
        </PortalKBadge>
      </div>
      <CloseButton
        class="close-button"
        @click="$emit('close')"
      />
    </div>
    <div
      v-if="!!slots.default"
      class="sidebar-header-content"
    >
      <slot name="default" />
    </div>
  </header>
</template>

<script setup lang="ts">
import type { BadgeAppearance } from '@kong/kongponents'

defineEmits<{
  (e: 'close'): void
}>()

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  alignTitle: {
    type: String as PropType<'flex-start' | 'center'>,
    default: 'center',
  },
  size: {
    type: String as PropType<'small' | 'large'>,
    default: 'small',
  },
  badgeText: {
    type: String,
    required: false,
    default: '',
  },
  badgeAppearance: {
    type: String as PropType<BadgeAppearance>,
    required: false,
    default: 'neutral',
  },
})

const slots = defineSlots<{
  default(): any
}>()

const computedSize = computed((): 'small' | 'large' => props.size === 'small' ? 'small' : 'large')
</script>

<style lang="scss" scoped>
.sidebar-header {
  background-color: var(--kui-color-background, $kui-color-background);
  border-bottom: var(--kui-border-width-10, $kui-border-width-10) solid var(--kui-color-border, $kui-color-border);
  display: flex;
  flex-direction: column;
  gap: var(--kui-space-70, $kui-space-70);
  padding: var(--kui-space-50, $kui-space-50) var(--kui-space-50, $kui-space-50);
  position: sticky;
  top: 0;

  @media (min-width: $kui-breakpoint-tablet) {
    padding: var(--kui-space-70, $kui-space-70);
  }

  &.large {
    min-height: $header-height;
  }
}

.sidebar-header-info {
  align-items: v-bind('$props.alignTitle');
  display: flex;
  gap: var(--kui-space-20, $kui-space-20);
  height: 100%;
  justify-content: space-between;
  width: 100%;

  &-content {
    align-items: baseline;
    display: flex;
    gap: var(--kui-space-40, $kui-space-40);
    justify-content: space-between;
    width: 100%;
  }

  .title {
    color: var(--kui-color-text, $kui-color-text);
    flex-grow: 1;
    font-family: var(--kui-font-family-text, $kui-font-family-text);
    font-size: var(--kui-font-size-30, $kui-font-size-30);
    font-weight: var(--kui-font-weight-bold, $kui-font-weight-bold);
    line-height: var(--kui-line-height-30, $kui-line-height-30);
    word-break: break-word;

    @media (min-width: $kui-breakpoint-tablet) {
      font-size: var(--kui-font-size-60, $kui-font-size-60);
      line-height: var(--kui-line-height-60, $kui-line-height-60);
    }
  }
}

.close-button {
  @media (min-width: $kui-breakpoint-tablet) {
    display: none;
  }
}

.sidebar-header-content {
  font-size: var(--kui-font-size-30, $kui-font-size-30);
  font-weight: var(--kui-font-weight-regular, $kui-font-weight-regular);
  line-height: var(--kui-line-height-30, $kui-line-height-30);
  width: 100%;
}
</style>
