<template>
  <div
    v-if="leftButton.visible || rightButton.visible"
    class="layout-mobile-page-header"
  >
    <div class="left-actions">
      <button
        v-if="leftButton.visible"
        class="layout-mobile-page-header-button"
        data-testid="layout-mobile-page-header-button-left"
        type="button"
        @click="mobileSidebarLeftIsVisible = true"
      >
        <DocumentListIcon
          display="block"
          :size="KUI_ICON_SIZE_40"
        />
        <div>{{ leftButton.text || t('navigation.docs') }}</div>
      </button>
    </div>
    <div class="right-actions">
      <button
        v-if="rightButton.visible"
        class="layout-mobile-page-header-button"
        data-testid="layout-mobile-page-header-button-right"
        type="button"
        @click="mobileSidebarRightIsVisible = true"
      >
        <div>{{ rightButton.text || t('navigation.on_this_page') }}</div>
        <ChevronRightIcon
          display="block"
          :size="KUI_ICON_SIZE_40"
        />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DocumentListIcon, ChevronRightIcon } from '@kong/icons'
import { KUI_ICON_SIZE_40 } from '@kong/design-tokens'

interface LayoutPageHeaderButtonConfig {
  /** Is the button visible. Defaults to `true` */
  visible?: boolean
  /** The button text */
  text?: string
}

defineProps({
  leftButton: {
    type: Object as PropType<LayoutPageHeaderButtonConfig>,
    default: () => ({
      visible: true,
      text: '',
    }),
  },
  rightButton: {
    type: Object as PropType<LayoutPageHeaderButtonConfig>,
    default: () => ({
      visible: true,
      text: '',
    }),
  },
})

const { mobileSidebarLeftIsVisible, mobileSidebarRightIsVisible } = storeToRefs(useLayoutStore())
const { t } = useI18n()
</script>

<style lang="scss" scoped>
// !Important: This class name is bound in `layers/core/app/components/layout/LayoutBase.vue`
.layout-mobile-page-header {
  align-items: center;
  background-color: var(--kui-color-background, $kui-color-background);
  border-bottom: var(--kui-border-width-10, $kui-border-width-10) solid var(--kui-color-border, $kui-color-border);
  color: var(--kui-color-text-neutral, $kui-color-text-neutral);
  display: flex;
  font-size: var(--kui-font-size-30, $kui-font-size-30);
  font-weight: var(--kui-font-weight-regular, $kui-font-weight-regular);
  height: auto !important; // Prevent styles in LayoutBase from affecting the height
  justify-content: space-between;
  line-height: var(--kui-line-height-30, $kui-line-height-30);
  padding: var(--kui-space-0, $kui-space-0) var(--kui-space-70, $kui-space-70);
  position: sticky;
  top: $header-height;
  width: 100%;
  z-index: 100;

  @media (min-width: $kui-breakpoint-tablet) {
    display: none;
  }

  .left-actions,
  .right-actions {
    align-items: center;
    display: flex;
    gap: var(--kui-space-40, $kui-space-40);
  }

  .left-actions {
    justify-content: flex-start;
  }

  .right-actions {
    justify-content: flex-end;
  }
}

button.layout-mobile-page-header-button {
  align-items: center;
  background-color: var(--kui-color-background-transparent, $kui-color-background-transparent);
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  font-family: var(--kui-font-family-text, $kui-font-family-text);
  font-size: var(--kui-font-size-30, $kui-font-size-30);
  font-weight: var(--kui-font-weight-medium, $kui-font-weight-medium);
  gap: var(--kui-space-20, $kui-space-20);
  justify-content: center;
  line-height: var(--kui-line-height-30, $kui-line-height-30);
  padding: var(--kui-space-50, $kui-space-50) var(--kui-space-0, $kui-space-0);
  user-select: none;
  white-space: nowrap;
}
</style>
